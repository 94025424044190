:root {
    --ford-blue: #03145b;
    --ford-twilight: #00142E;
    --ford-skyview: #066FEF;
    --ford-grabber: #1700F4;
    --ford-skyview-hover: #0142BD;

    --ford-gray-1: #F0F0F0;
    --ford-gray-2: #C5C5C5;
    --ford-gray-3: #9C9C9C;
    --ford-gray-4: #8C8C8C;
    --ford-gray-5: #5E5E5E;
    --ford-gray-6: #4D4D4D;
    --ford-black: #010203;
    --ford-disabled: #ababab;


    --banner-text-size: 70px;
    --heading-text-size: 48px;
    --subheading-text-size: 34px;
    --text-size: 24px;
    --subtext-text-size: 20px;
    --body-text-size: 16px;
    --help-text-size: 12px;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../public/fonts/FordF-1-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../public/fonts/FordF-1-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ford F-1';
    src: url('../public/fonts/FordF-1-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html, body {
    font-family: "Ford F-1", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

html, body {
    font-size: 24px;
    font-weight: 400;
    background: #fff !important;
}

img{
    max-width: 100%;
}

html[lang="en"] [language="ar"]{
    display: none !important;
}

html[lang="ar"] [language="en"]{
    display: none !important;
}

.arabic{
    text-align: right;
}


/*
h1{
font-size: 70px;
line-height: 90px;
}
*/

h2{
    font-size: var(--heading-text-size);
    color: var(--ford-blue) !important;
}

h3{
    color: var(--ford-blue);
    font-size: var(--subheading-text-size);
    line-height: 40px;
    font-weight: 700;
}

h4{
    font-size: var(--text-size);
    line-height: 40px;
    font-weight: 500;
    color: var(--ford-gray-6);
}


.topnav{
    padding-top: 0;   
    border-bottom: 1px solid #999;
}

.topnav .top{
    background: var(--ford-gray-1);
    min-height: 30px;
    width: 100%;
    color: var(--ford-blue);
    padding-top: 5px;
    padding-bottom: 5px;
}

.topnav .top .container div div{
    margin-left: 25px;
}

.topnav .top a{
    color: var(--ford-blue);
    text-decoration: none;
}

.topnav .top span{
    cursor: pointer;
}

.topnav .top span svg{
    position: relative;
    top: -2px;
}

.lincoln{
    display: none !important;
}

.navbar{
    padding: 0 !important;
    background-color: #FFF;
}

.navbar-toggler{
    margin-top: 10px;
}

.navbar-toggler-icon{
    width: 30px !important;
    height: 30px !important;
}

.navbar .nav-item{
    margin-right: 10px;
}

.mobile .nav-item a span, 
.navbar .nav-item a {
    color: var(--ford-blue);
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
}

.navbar .nav-item a.large{
    font-size: 18px;
}

.navbar-nav .dropdown-menu{
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, .1);
    background: #fff;
}

.navbar-nav .dropdown-menu li{
    /*    padding: 5px;*/
}

.navbar-nav .dropdown-menu li a{
    padding: 10px 15px !important;
    padding-left: 0 !important;
    display: inline-block;
    border-bottom: 1px solid var(--ford-gray-1);
    color: var(--ford-gray-5);
    font-weight: normal;
}

.navbar-nav .dropdown-menu li a:hover{
    background: #fff;
    color: var(--ford-blue);
    border-color: var(--ford-blue);
}

.navbar-nav.rightnav li{
    margin-left: 30px;
}

.navbar-nav form{
    position: relative;
    margin-top: 8px;
}

.navbar-nav svg.bi-search{
    position: absolute;
    top: 9px;
    left: 15px;
    width: 14px;
    height: 14px;
    color: var(--ford-skyview);
}

.navbar-nav input[type="search"]{
    border: 1px solid var(--ford-gray-2);
    border-radius: 25px;
    height: 32px;
    width: 230px;
    line-height: 32px;
    padding-left: 40px;
    font-size: 14px;
    background: none;
    padding-top: 7px;
    padding-bottom: 7px;
}

.navbar-nav.rightnav svg.bi-cart3{
    /*    color: #4d4d4d;*/
    width: 40px;
    height: 40px;
}

.navbar .nav-item.cart-icon{
    position: relative;
}

.navbar .nav-item.cart-icon span.count{
    position: absolute;
    top: 2px;
    left: 25px;
    /* background: var(--ford-skyview); */
    color: var(--ford-skyview);
    padding: 0px 4px;
    font-size: 13px;
    border-radius: 50%;
    font-weight: 700;
}

.navbar .nav-item:first-child a{
    padding-left: 0;
}

.navbar form[role="search"]{
    position: relative;
}

.navbar form[role="search"] .suggestions{
    position: absolute;
    top: 40px;
    background: var(--ford-gray-1);
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 0 10px 10px rgba(0,0,0,.1);
    background: #fff;
}

.navbar form[role="search"] .suggestions{
    padding: 0;
    z-index: 100;
}

.navbar form[role="search"] .suggestions li{
    font-size: var(--body-text-size);
    padding: 8px 20px;
    border-bottom: 1px solid var(--ford-gray-1);
    list-style: none;
    color: var(--ford-blue);
    cursor: pointer;
}

.navbar form[role="search"] .suggestions li:hover {
    background: var(--ford-gray-5);
    color: #fff;
}

.navbar .nav-item.language{
    margin-top: 2px;
    margin-left: 20px;
    margin-right: 20px;
}

.navbar .nav-item.language a{
    /*    font-weight: normal;*/
    font-size: 14px;
}

.navbar .nav-item.language span{
    font-size: 15px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.country-change-msg{
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 5px solid red;
    border-top: 1px solid red;
}

.country-change-msg p{
    font-size: var(--body-text-size);
    color: var(--ford-blue);
    margin-bottom: 0;
}

.country-change-msg p svg{
    color: red;
    margin-right: 10px;
}

.country-change-msg p a{
    color: var(--ford-skyview);
    text-decoration: none;
    margin-left: 50px;
    transition-duration: .25s;
    font-weight: bold;
}

.country-change-msg p a:hover{
    color: var(--ford-skyview-hover);
}

.cart-updated-msg{
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 5px solid green;
    border-top: 1px solid green;
}

.cart-updated-msg p{
    font-size: var(--body-text-size);
    color: var(--ford-blue);
    margin-bottom: 0;
}

.cart-updated-msg p svg{
    color: green;
    margin-right: 10px;
}

.cart-updated-msg p a{
    color: var(--ford-skyview);
    text-decoration: none;
    margin-left: 50px;
    transition-duration: .25s;
    font-weight: bold;
}

.cart-updated-msg p a:hover{
    color: var(--ford-skyview-hover);
}

.order-submitted-msg{
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 5px solid green;
    border-top: 1px solid green;
}

.order-submitted-msg p{
    font-size: var(--body-text-size);
    color: var(--ford-blue);
    margin-bottom: 0;
}

.order-submitted-msg p svg{
    color: green;
    margin-right: 10px;
}

.order-submitted-msg p a{
    color: var(--ford-skyview);
    text-decoration: none;
    margin-left: 50px;
    transition-duration: .25s;
    font-weight: bold;
}

.order-submitted-msg p a:hover{
    color: var(--ford-skyview-hover);
}

.country-change-msg{

}

.ft{
    margin-top: 70px;
    background: var(--ford-blue);
    padding-top: 0px;
    padding-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    font-stretch: 75%;
    line-height: 16px;
}

.ft .links{
    padding-top: 25px;
    padding-bottom: 25px;
}

.ft .links a{
    padding-left: 15px;
    padding-right: 15px;    
    color: #fff;
    text-decoration: none;
    display: block;
    margin: 10px 0px;
    border: none;
}

.ft .links a:last-child{
    border-right: none;
}

.ft .links a:hover{
    text-decoration: underline;
}

.footer{
    background: var(--ford-gray-1);
    padding-top: 70px;
}


.footer .follow{
    padding-bottom: 50px;
}

.footer .bottom{
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid var(--ford-gray-2);
}


.footer .follow p span{
    color: var(--ford-black);
    font-weight: 500;
    font-size: 15px;
    position: relative;
}

.footer .follow img{
    color: var(--ford-blue);
    margin-left: 20px;
    width: auto;
    height: 26px;

}

.footer .bottom .col-sm-10 a,
.footer .bottom span{
    color: var(--ford-gray-6);
    margin-right: 20px;
    text-decoration: none;
    font-size: 13px;
    margin-top: 15px;
    display: inline-block;
}

.footer .bottom a span,
.footer .bottom span span{
    margin-right: 0;
    margin-left: 5px;
}

.footer .bottom img{
    max-height: 50px;   
}

.page-ds .ford-btn{
    margin-top: 30px;
}

button.ford-btn,
.ford-btn a{
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    padding-left: 32px;
    padding-right: 32px;
    color: #fff;
    background: var(--ford-skyview);
    text-align: center;
    border-radius: 20px;
    transition-duration: .25s;
}

button.ford-btn:hover,
.ford-btn a:hover{
    background: var(--ford-skyview-hover);
    color: #fff;
}

.add-item-btn{
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
    background: var(--ford-skyview);
    text-align: center;
    border-radius: 20px;
    transition-duration: .5s;
    display: inline-block;
    padding: 7px 33px;
    cursor: pointer;
}

.add-item-btn:hover{
    background: var(--ford-skyview-hover);
    color: #fff;
}

.breadcrumb{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
}

.breadcrumb a{
    color: var(--ford-blue);
    text-decoration: none;
    font-size: var(--body-text-size);
}

.breadcrumb svg{
    color: var(--ford-blue);
    position: relative;
    top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
}

.pagination{
    padding-bottom: 50px;
}

.pagination .links{
    text-align: center;
    width: 100%;
}

.pagination .links .prev a{
    font-size: var(--body-text-size);
    text-decoration: none;
    color: var(--ford-skyview);
}

.pagination .links .current{
    margin-left: 20px;
    margin-right: 20px;
    background: var(--ford-gray-1);
    padding: 4px 10px;
    text-align: center;
    border-radius: 50%;
    font-size: var(--body-text-size);
    font-weight: 600;
    color: var(--ford-blue);
}

.pagination .links .next a{
    font-size: var(--body-text-size);
    text-decoration: none;
    color: var(--ford-skyview);
}

/* Pagination Container */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* Pagination List */
.pagination-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Pagination Items */
.pagination-list .page-item {
    margin: 0 0.25rem;
}

/* Pagination Links */
.pagination-list .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px;
    padding: 0.5rem;
    /* border: 1px solid #d1d5db; */
    border-radius: 0.375rem;
    background-color: #ffffff;
    color: #374151;
    font-size: 18px;
    line-height: 1.25rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

/* Hover State */
.pagination-list .page-link:hover {
    background-color: var(--ford-gray-1);
    border-color: #9ca3af;
}

/* Active State */
.pagination-list .page-item.active .page-link {
    background-color: #03145b;
    color: #ffffff;
}

/* Disabled State */
.pagination-list .page-item.disabled .page-link {
    color: #9ca3af;
    pointer-events: none;
    background-color: #ffffff;
    border-color: #d1d5db;
}

/* Previous and Next buttons */
.pagination-list .page-item:first-child .page-link,
.pagination-list .page-item:last-child .page-link {
    font-weight: bold;
}

/* Ellipsis */
.pagination-list .page-item .page-link span {
    color: #6b7280;
}

/* Responsive Design */
@media (max-width: 640px) {
    /*
    .pagination-list .page-link {
    min-width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    font-size: 0.75rem;
}
    */

    .pagination-list .page-item {
        margin: 0 0.125rem;
    }
}

.fitment{
    margin-top: 50px;
    margin-bottom: 20px;
}

.fitment a{
    color: var(--ford-blue);
    text-decoration: none;
    font-size: var(--body-text-size);
    font-weight: bold;
}

.fitment svg{
    width: 40px;
    height: 40px;
    margin-right: 15px;
    margin-bottom: 25px;
}


/* * * * * COUNTRY SELECTION PAGE - START * * * * */

.page-cs .country-selection{
    padding-top: 40px;
    padding-bottom: 50px;
    min-height: 50vh;
}

.page-cs .country-selection .countries{
    padding-top: 20px;
}

.page-cs .country-selection .countries.br{
    border-right: 1px solid var(--ford-gray-6);
}

.page-cs .country-selection .countries h3{
    color: var(--ford-blue);
    font-size: var(--subheading-text-size);
    line-height: 40px;
    font-weight: 700;
}

.page-cs .country-selection .countries ul{
    padding-right: 60px;
    padding-left: 0;
}

.page-cs .country-selection .countries.br ul{
    padding-right: 0px;
    padding-left: 60px;
}

.page-cs .country-selection .countries ul li{
    list-style: none;
}

.page-cs .country-selection .countries ul li a{
    color: var(--ford-gray-6);
    font-size: var(--text-size);
    font-weight: 400;
    line-height: 24px;   
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    text-decoration: none;
}
/* * * * * COUNTRY SELECTION PAGE - END * * * * */

/* ============================================ */

/* * * * * DEALER SELECTION PAGE - START * * * * */
.page-ds{
    padding-bottom: 50px;
}

.page-ds h3{
    padding-top: 60px;
    padding-bottom: 20px;
}

#dealersAccordion{
    margin-top: 30px;
}

.accordion-item{
    border: none;
    border-bottom: 1px solid #9c9c9c;
    border-top: 1px solid #9c9c9c;
    border-radius: 0 !important;
}

.accordion-item .accordion-header button{
    color: var(--ford-blue);
    background: none;
    font-size: var(--subtext-text-size);
    font-weight: 400;
}

.page-ds .accordion-item .accordion-collapse{
    background: #f2f2f2;
}

.page-ds .accordion-item .accordion-collapse .form-check{
    margin-bottom: 20px;
    margin-top: 20px;
}

.page-ds .accordion-item .accordion-collapse label{
    color: var(--ford-gray-6);  
    font-size: var(--body-text-size);
}

.page-ds .accordion-item .accordion-collapse label span.name{
    display: block;
    width: 1000%;
}

.page-ds .accordion-item .accordion-collapse label span.address{
    max-width: 60%;
    display: inline-block;
    padding-left: 20px;
}

/* * * * * DEALER SELECTION PAGE - END * * * * */

/* ============================================ */

/* * * * * HOME PAGE - START * * * * */
.page-home .banner-new{
/*
    background-image: url('../public/images/MEA_Accessories_asset.jpg');
    height: 90vh;
    background-size: cover;
    background-position: center center;
*/
    position: relative;
}

.page-home .banner-new .content{
    position: absolute;
    color: #fff;
    bottom: 50px;
}

.page-home .banner-new h1 {
    font-size: 48px;
    font-weight: 600;
}

.page-home .banner-new h3 {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
}

.banner-Lincoln .content {
    top: 1rem; 
    /*    left: 1rem;*/
}
.page-home .main-form{
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
    background: var(--ford-gray-1);
    background: #f5f6f7;
    margin-top: 50px;
    border-radius: 10px;
    /*    border: 1px solid var(--ford-gray-2);*/
    text-align: center;
}

.page-home .main-form h4{
    color: var(--ford-blue);
    font-size: 32px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 10px
}

.page-home .main-form p{
    color: #4d4d4d;
    padding-bottom: 20px;
    /*    font-size: 18px;*/
}

.page-home .main-form form{
    text-align: center;
    margin-bottom: 20px;
}

.page-home .main-form form select{
    padding: 5px 20px;
    margin-bottom: 15px;
    font-size: var(--subtext-text-size);
    border-radius: 10px;
}

form button:disabled{
    background: var(--ford-disabled) !important;
    border: none;
    color: var(--ford-gray-1) !important;
}

.accessory-cats{
    /*    margin-top: 40px;*/
    /*    background: var(--ford-gray-2);*/
    padding-top: 70px;
}

.accessory-cats .heading{
    color: var(--ford-blue);
    font-size: 32px;
    margin-bottom: 50px;
}

.accessory-cats .card{
    border: none;
    margin-bottom: 80px;
    background: none;
}

.accessory-cats .card img{
    border-radius: 10px;
}

.accessory-cats .name{
    color: var(--ford-blue);
    font-size: 32px;
    padding-left: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.accessory-cats .desc{
    color: #fff;
    font-size: 14px;
    padding-left: 10px;
}

.accessory-cats .bottom{
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
}

.accessory-cats .bottom a{
    height: 40px;
    display: inline-block;
    color: var(--ford-blue);
    text-align: center;
    border: 1px solid var(--ford-blue);
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

/* * * * * HOME PAGE - END * * * * */

/* ============================================ */

/* * * * * ACCESSORY DETAIL PAGE - START * * * * */

.backtores{
    padding-top: 20px;
    padding-bottom: 20px;
}

.backtores a{
    font-size: 18px;
    color: var(--ford-skyview);
    text-decoration: none;
}

.details h3{
    font-size: 34px;
    font-weight: 500;
}

.details h4{
    color: var(--ford-blue);
    font-size: 25px;
    font-weight: 500;
}

.image img{
    max-width: 100%;
    border-radius: 10px;
}

.details{
    color: var(--ford-gray-6); 
    padding-bottom: 50px;
}

.details .info{
    padding-top: 20px;   
}

.details .desc{
    padding-bottom: 20px;
    font-size: var(--subtext-text-size);
    padding-top: 50px;
}

.details .price{
    font-weight: 700;
    font-size: var(--subtext-text-size);
}

.details .price .heading{
    margin-bottom: 0;
}

.details .quantity{
    margin-top: 15px;
    margin-bottom: 30px;
}

.details .quantity .heading{
    margin-bottom: 10px;
    font-size: var(--subtext-text-size);
    color: var(--ford-gray-6);
}

.details .quantity .buttons{
    display: inline-block;
    border: 1px solid var(--ford-gray-6);
    border-radius: 5px;
}

.details .quantity .buttons span{
    padding: 5px 20px;
    font-weight: 500;
    border-left: 1px solid;
    border-right: 1px solid;
    display: inline-block;
}

.details .quantity .buttons span.plus,
.details .quantity .buttons span.minus{
    display: inline-block;
    border: none;
    padding: 5px 14px;
    position: relative;
    top: -2px;
    font-size: 18px;
    cursor: pointer;
}

.details .additional{
    padding-top: 20px;
    font-size: var(--body-text-size);
}

.details .additional p{
    margin-bottom: 10px;
}

.details .additional .heading{
    font-weight: 700;
    font-size: var(--subtext-text-size);
}

.details .additional ul li{
    list-style: square;
    margin-bottom: 10px;
}

.details .additional .note{
    color: var(--ford-gray-5);   
    font-style: italic;
    padding-top: 10px;
    font-size: var(--help-text-size);
}

/* * * * * ACCESSORY DETAIL PAGE - END * * * * */

/* ============================================ */

/* * * * * ACCESSORY CATEGORY PAGE - START * * * * */
#categoryAccordion{
    margin-top: 40px;
}

#categoryAccordion .accordion-body{
    padding: 0;
}

.filter-form{
    color: var(--ford-blue);
    padding: 25px 35px;
    background: #f5f6f7;
    margin-bottom: 50px;
    /*    border: 1px solid var(--ford-gray-2);*/
    /*    border-radius: 10px;*/
}

.filter-form .heading{
    color: var(--ford-gray-6);
    font-size: var(--subtext-text-size);
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
}

.filter-form form .label{
    font-size: 13px;
    color: var(--ford-gray-6);
    margin-bottom: 0;
}

.filter-form form select{
    padding: 6px 20px;
    font-size: var(--subtext-text-size);
    border-radius: 10px;
}

.accessories{
    margin-top: 30px;
    padding-bottom: 50px
}

.accessories h2{
    margin-bottom: 50px;
}

.accessories h3{
    font-weight: 400;
    margin-top: 20px;
}

.accessories .count-filter{
    float: right;
    margin-bottom: 30px;
}

.accessories .count-filter p{
    color: var(--ford-gray-6);
    margin-bottom: 5px;
    font-size: var(--subtext-text-size);
}

.accessories .count-filter .filter{
    border: 1px solid var(--ford-gray-6);
    border-radius: 3px;
}

.accessories  .count-filter .filter span{
    padding: 13px 15px;
    line-height: 1;
    display: inline-block;
    border-right: 1px solid;
    cursor: pointer;
    font-size: var(--body-text-size);
}

.accessories  .count-filter .filter span:last-child{
    border: none;
}

.accessories .count-filter .filter span.active{
    color: #fff;
    background: var(--ford-blue);
}

.accessories .accessory{
    color: var(--ford-blue);
    background: #fff;
    margin-bottom: 50px;
}

.accessories .accessory img{
    border-radius: 10px;
}

.accessories .accessory .cat-name{
    margin: 0;
}

.accessories .accessory .cat-name a{
    font-size: var(--help-text-size);
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    display: inline-block;
    color: inherit;
    text-decoration: none;
}

.accessories .accessory .name a{
    padding-left: 10px;
    font-size: var(--body-text-size);
    line-height: 22px;
    font-weight: 600;
    display: inline-block;
    color: var(--ford-twilight);
    text-decoration: none;
}

.accessories .show-more{
    text-align: center;
}

.accessories .show-more a{
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    padding: 0 32px;
    border: 1px solid;
    color: var(--ford-blue);
    text-decoration: none;
    display: inline-block;
    border-radius: 20px;
}

.accessories .show-more a svg{
    margin-left: 10px;
}

/* * * * * ACCESSORY CATEGORY PAGE - END * * * * */

/* ============================================ */

/* * * * * FAQ PAGE - START * * * * */

.faq{
    padding-bottom: 50px;
}

.faq h2{
    margin-bottom: 30px;
}

.faq h2.accordion-header{
    margin-bottom: 0;
}

.faq p{
    margin-bottom: 10px;
    color: var(--ford-gray-5);
    font-size: var(--body-text-size);
}

.faq ul li{
    margin-bottom: 10px;
    color: var(--ford-gray-5);
    font-size: var(--body-text-size);
}

.faq .banner-img{
    margin-bottom: 50px;
    width: 100%;
}

/* * * * * FAQ PAGE - END * * * * */

/* ============================================ */

/* * * * * CART PAGE - START * * * * */

.cart{
    margin-top: 40px;
    padding-bottom: 50px;
}

.cart .products{
    color: var(--ford-gray-6);   
}

.cart .products .product{
    margin-top: 30px;
}

.cart .products .product img{
    border-radius: 10px;
}

.cart .products .product .name{
    font-size: var(--text-size);
    font-weight: 700;
    color: var(--ford-gray-6);
    margin-bottom: 10px;
    margin-top: 15px;
}

.cart .products .product .partno{
    margin-bottom: 10px;
    font-size: var(--body-text-size);
}

.cart .products .product .price{
    font-weight: 700;
    margin-bottom: 10px;
    font-size: var(--subtext-text-size);
}

.cart .products .product .quantity{
    margin-bottom: 15px;
    font-size: var(--subtext-text-size);
}

.cart .products .product .quantity .heading{
    margin-bottom: 5px;
    font-size: var(--help-text-size);
}

.cart .products .product .quantity .buttons{
    display: inline-block;
    border: 1px solid var(--ford-gray-3);
    border-radius: 5px;
}

.cart .products .product .quantity .buttons span{
    padding: 5px 15px;
    font-weight: 500;
    border-left: 1px solid;
    border-right: 1px solid;
    display: inline-block;
    font-size: var(--body-text-size);
}

.cart .products .product .quantity .buttons span.plus,
.cart .products .product .quantity .buttons span.minus{
    display: inline-block;
    border: none;
    padding: 4px 8px;
    position: relative;
    top: -2px;
    font-size: 18px;
    cursor: pointer;
}

.cart .products .product .remove a{
    color: var(--ford-skyview);
    font-size: 13px;
    transition-duration: .25s;
    text-decoration: none;
}

.cart .products .product .remove a:hover{
    color: var(--ford-skyview-hover);
}

.cart .subtotal{
    font-size: var(--subtext-text-size);
    font-weight: 700;
    color: var(--ford-gray-6);
    margin-top: 50px;
    margin-bottom: 30px;
}

.cart .ford-btn a{
    display: inline-block;
    line-height: 38px;
    text-decoration: none;
}

.cart .empty-cart{
    margin-top: 30px;
    color: var(--ford-gray-4);
}

/* * * * * CART PAGE - END * * * * */

/* ============================================ */

/* * * * * CHECKOUT PAGE - START * * * * */

.checkout{
    padding-bottom: 50px;
}

.checkout h3{
    margin-bottom: 20px;
}

.checkout .distributor{
    margin-bottom: 20px;
}

.checkout .distributor p{
    font-size: var(--body-text-size);
    color: var(--ford-gray-6);
}

.checkout .distributor p.heading{
    color: var(--ford-gray-6);
    font-weight: 700;
    font-size: var(--text-size);
}

.checkout .customer{
    margin-bottom: 50px;
}

.checkout .customer p{
    color: var(--ford-gray-6);
    font-size: var(--body-text-size);
}

.checkout .customer p.heading{
    font-weight: 700;
    font-size: var(--text-size);
}

.checkout .customer form{
    opacity: 1.7;
}

.checkout .customer form input{
    margin-bottom: 20px;
    border-radius: 3px;
    border-color: var(--ford-gray-6);
    font-size: var(--body-text-size);
}

.checkout .customer form label{
    font-size: var(--help-text-size);
    color: var(--ford-gray-6);
}

.checkout .order{
    border-top: 1px solid var(--ford-gray-6);
    padding-top: 20px;
}

.checkout .order .heading{
    color: var(--ford-gray-6);
    font-weight: 700;
    font-size: var(--text-size);
}

.checkout .order .products{
    opacity: 1;
}

.checkout .order .products .product{
    margin-bottom: 30px;
}

.checkout .order .products .product img{
    opacity: 1.2;
    border-radius: 10px;
}

.checkout .order .products .product .name{
    font-size: var(--subtext-text-size);
    font-weight: 700;
    color: var(--ford-gray-6);
    margin-bottom: 5px;
}

.checkout .order .products .product .partno{
    color: var(--ford-gray-6);
    margin-bottom: 0;
    font-size: var(--body-text-size);
}

.checkout .order .products .product span.price{
    font-weight: 700;
    color: var(--ford-gray-6);
    display: inline-block;
    width: 150px;
    font-size: var(--body-text-size);
}

.checkout .order .products .product span.quantity{
    font-weight: 700;
    color: var(--ford-gray-6);
    font-size: var(--body-text-size);
}

.checkout .order .details{
    margin-bottom: 50px;
}

.checkout .order .details p{
    margin-bottom: 10px;
}

.checkout .order .details p span.label{
    font-size: var(--subtext-text-size);
    font-weight: 700;
    width: 100px;
    display: inline-block;
}

.checkout .order .details p span.value{
    font-size: var(--subtext-text-size);
    font-weight: 700;
    width: 150px;
    display: inline-block;
    text-align: right;
}

.checkout .order .details p.total{
    width: 250px;
    border-top: 1px solid;
    padding-top: 10px;
}

.checkout .ford-btn{

}

.checkout .ford-btn a{
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.checkout p.note{
    font-style: italic;
    margin-top: 50px;
    font-size: var(--body-text-size);
    color: var(--ford-gray-3);
}


/* * * * * CHECKOUT PAGE - END * * * * */

/* ============================================ */



.banner-wrapper .carousel-item{
    height: 90vh;
    overflow: hidden;
}

.banner-wrapper .carousel-item video{
    max-height: 90vh;
    width: auto;
}

.banner-wrapper .carousel-inner{
    background: #000;
}

.banner-wrapper .carousel-item video {
    max-width: 100%;
    height: auto;
}






.navbar-nav li .divider{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #999;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile .navbar-nav{
    margin-top: 40px;
}

/*
.mobile::before{
content: "";
height: 2px;
position: absolute;
top: 95px;
left: 0;
width: 100%;
z-index: 100;
box-shadow: inset 0 0 0 transparent, 0 .2rem .2rem #00000080;
background-color: #fff;
}
*/

.mobile .nav-item a{
    padding: 7.5px 10px;
}

.mobile .nav-item.access-cat-link a{
    padding: 10px 25px;
}

.mobile form[role="search"]{
    position: relative;
    margin-bottom: 10px;
}

.mobile form[role="search"] svg{
    position: absolute;
    top: 14px;
    left: 15px;
    width: 20px;
    height: 20px;
    color: var(--ford-gray-6);
}

.mobile form[role="search"] input{
    width: 100%;
}

.mobile .bi-geo-alt,
.mobile .bi-shop-window,
.mobile .bi-cart3{
    width: 20px;
    height: 20px;
}

.country-selection .mobile ul li{
    list-style: none;
    padding: 10px;
}

.country-selection .mobile ul li a{
    color: var(--ford-gray-6);
    text-decoration: none;
}

.navbar .navbar-brand img{
    max-width: 300px;
    margin-top: 5px;
}

.navbar .navbar-brand{

}

.page-dealer .dealer-change-msg{
    display: none;
}

/*SM*/
@media (min-width: 576px) {

}

/*MD*/
@media (min-width: 768px) {
    .ft{
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .ft .links a{
        display: inline-block;
        border-right: 1px solid #fff;
    }
    .page-home .main-form{
        /*        top: -50px;*/
    }
    .accessory-cats .bottom{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .backtores{
        text-align: right;
    }
    .filter-form{
        margin-top: 50px;
    }
    .filter-form form select{
        /*        margin-bottom: 0px;*/
    }
    .cart{
        margin-top: 0px;
    }
    .cart .products .product .name{
        margin-top: 0px;
    }
    .cart .breadcrumb svg{
        top: -1px;
    }
}

/*LG*/
@media (min-width: 992px) {
    .navbar .navbar-brand{
        margin-right:30px;
    }

    .navbar .navbar-brand img{
        max-width: 100%;
        margin-top: 0px;
    }
    .details .info{
        padding-left: 20px;
        padding-top: 0px;
    }
    .details h4{
        padding-bottom: 20px;
    }

}

/*XL*/
@media (min-width: 1200px) {

}

/*XXL*/
@media (min-width: 1400px) {

}







.navbar-nav li .divider{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #999;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile .navbar-nav{
    margin-top: 40px;
}

/*
.mobile::before{
content: "";
height: 2px;
position: absolute;
top: 68px;
left: 0;
width: 100%;
z-index: 100;
box-shadow: inset 0 0 0 transparent, 0 .2rem .2rem #00000080;
background-color: #fff;
}
*/

.mobile .nav-item a{
    padding: 7.5px 10px;
}

.mobile .nav-item.access-cat-link a{
    padding: 10px 25px;
}

.mobile form[role="search"]{
    position: relative;
    margin-bottom: 10px;
}

.mobile form[role="search"] svg{
    position: absolute;
    top: 7px;
    left: 15px;
    width: 20px;
    height: 20px;
    color: var(--ford-gray-6);
}

.mobile form[role="search"] input{
    width: 100%;
}

.country-selection .mobile ul li{
    list-style: none;
    padding: 10px;
}

.country-selection .mobile ul li a{
    color: var(--ford-gray-6);
    text-decoration: none;
}

.navbar .navbar-brand{
    margin-right: 0;
}

.navbar .navbar-brand img{
    max-width: 300px;
    margin-top: 5px;
}

.footer .text-end{
    text-align: center !important;
}

.g-5, .gx-5 {
    --bs-gutter-x: 0rem;
}

/*SM*/
@media (min-width: 576px) {

}

/*MD*/
@media (min-width: 768px) {
    .ft{
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .ft .links a{
        display: inline-block;
        border-right: 1px solid #fff;
    }
    .accessory-cats .bottom{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .backtores{
        text-align: right;
    }
    .filter-form{
        margin-top: 50px;
    }
    .filter-form form select{
        /*        margin-bottom: 0px;*/
    }
    .cart{
        margin-top: 0px;
    }
    .cart .products .product .name{
        margin-top: 0px;
    }
    .cart .breadcrumb svg{
        top: -1px;
    }
}

/*LG*/
@media (min-width: 992px) {

    .navbar .navbar-brand img{
        max-width: 100%;
        margin-top: 0px;
    }
    .footer .text-end{
        text-align: right!important;
    }
    .page-home .banner-new h1 {
        font-size: var(--banner-text-size);
        line-height: 1;
    }

    .page-home .banner-new h3 {
        font-size: var(--subheading-text-size);
        line-height: 1;
    }
    .page-home .main-form h4{
        font-size: var(--heading-text-size);
    }
    .accessory-cats .heading{
        font-size: var(--heading-text-size);
    }
    .accessory-cats .name{
        font-size: var(--subheading-text-size);
    }
    .g-5, .gx-5 {
        --bs-gutter-x: 3rem;
    }

}

/*XL*/
@media (min-width: 1200px) {

}

/*XXL*/
@media (min-width: 1400px) {

}





@media (max-width: 800px) {
    .mobile .navbar-brand{
        top: -5px;
        overflow: hidden;
    }

    .mobile .navbar-brand img{
        margin-top: 0;
        /*        margin-right: -90px;*/
    }

    .mobile .navbar-toggler{
        margin-top: 0;
        border: none;
        box-shadow: none !important;
        z-index: 1000;
    }

    .mobile .navbar-toggler-icon{
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2303145b' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .page-home .content.mobile{
        text-align: center;
        color: var(--ford-blue);
        padding-top: 25px;
    }

    .page-home .main-form{
        padding-left: 30px;
        padding-right: 30px;
    }

    .g-5, .gx-5{
        --bs-gutter-x: 1.5rem !important;
    }
    .filter-form{
        margin-bottom: 0;
    }
    .filter-form form select{
        margin-bottom: 15px;
    }

    .banner-wrapper .carousel-item{
        height: 54.74vw;
    }


}

.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin-top: 0px;
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.navbar-nav .nav-link{
    border-bottom: 2px solid #fff;
}

.navbar-nav .nav-link.active, 
.navbar-nav .nav-link.show{
    color: var(--ford-skyview) !important;
    border-bottom: 2px solid var(--ford-skyview);
}

.navbar-collapse{
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.mobile .icons .nav-item a{
    text-align: center;
}


@media (max-width: 320px) {

}

@media (max-width: 479px) {
    .footer .follow p span{
        display: block;
    }
}

@media (max-width: 575px) {

}

@media (max-width: 767px) {
    .footer .bottom .col-md-10>span,
    .footer .bottom a{
        display: block;
        text-align: center;
    }
    .footer .bottom .col-md-10{
        margin-bottom: 20px;
    }
    .page-home .banner-new .content{
        width: 100%;
        left: 0;
        text-align: center;
        bottom: 110px;
    }
    .page-home .banner-new h1{
        font-size: 28px;
        margin-bottom: 5px;
    }
    .page-home .banner-new h3{
        font-size: 20px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .page-home .banner-new{
        height: 66.66vw;
    }
}

@media (max-width: 1199px) {
    .navbar .nav-item.cart-icon span.count {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 1400px) {

}




















