.carousel-item {
    height: 100vh;
    position: relative;
}

.carousel-item > div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

/* Prevent image shift during transition */
.carousel-inner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

/* Ensure smooth transitions */
.carousel-item {
    transition: transform .6s ease-in-out;
}

/* Fix background image container */
.carousel-item .d-inline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
} 

.banner-new.ford{
    height: 100%;
}

@media (max-width: 768px) {
    .container.Ford .main-form {
        position: relative;
        margin-top: 66vh;
    }
}