html[dir="rtl"] .footer .follow img{
    margin-left: 0;
    margin-right: 20px;
}

html[dir="rtl"] .footer .bottom span span{
    margin-right: 5px;
    margin-left: 0;
}

html[dir="rtl"] .navbar .nav-item:first-child a{
    padding-left: 12px;
    padding-right: 0;
}

html[dir="rtl"] .navbar .nav-item{
    margin-left: 10px;
    margin-right: 0;
}

html[dir="rtl"] .navbar-nav svg.bi-search{
    left: 25px;
}

html[dir="rtl"] .navbar .navbar-brand {
    left: 0px;
    right: unset;
}

html[dir="rtl"] .country-change-msg p svg{
    margin-right: 0;
    margin-left: 10px;
}

html[dir="rtl"] .country-change-msg p a{
    margin-left: 0px;
    margin-right: 50px;
}

html[dir="rtl"] input{
    text-align: right;
}

html[dir="rtl"] .fitment svg{
    margin-right: 0px;
    margin-left: 15px;
}

html[dir="rtl"] .page-cs .country-selection .countries.br{
    border: none;
}

html[dir="rtl"] .page-cs .country-selection .countries.arabic{
    border-right: 1px solid var(--ford-gray-6);
    padding-right: 50px;
}



html[dir="rtl"] .backtores{
    text-align: left;
}

@media (min-width: 992px) {
    html[dir="rtl"] .navbar .navbar-brand {
        margin-right: -160px !important;
        right: 50%;
    }
}